import React from 'react';
import banner from '../assets/images/banner3.png';
import { Phone, Mail, User, Briefcase, MessageCircle, FileText } from 'lucide-react';
import { decodeToken } from '../utils/utils';
import CasosRecientes from '../components/common/CasosRecientes';
import HomeAbogado from '../components/common/HomeAbogado';
import NuevosClientes from '../components/common/NuevosClientes';
import AceesosDirectos from '../components/common/AccesosDirectos';
let idRol = '';

function setIdRol() {
  const decoded = decodeToken();
  if (!decoded) {

  } else {
    idRol = decoded.idRol;
  }

  return idRol;

};
setIdRol();

const whatsappNumber = '50683231837';
const whatsappMessage = 'Hola, me gustaría agendar una consulta.';

const createWhatsAppLink = () => {
  return `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
};

const Home = () => {
  return (
    <>
        <div className="bg-gray-50 h-svh">
          {/* Banner Section */}
          <section className="w-full bg-gradient-to-b from-gray-800 to-gray-700 text-white py-20">
            <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 mb-10 md:mb-0">
                <h1 className="text-4xl md:text-5xl font-bold mb-4">Lic. Mariana Chacón Ramírez</h1>
                <p className="text-xl mb-6">Abogada y Notaria Pública especializada en Derecho de Familia</p>
                <a
                  href={createWhatsAppLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gold text-gray-900 py-2 px-6 rounded-full font-semibold hover:bg-yellow-500 transition duration-300 inline-flex items-center"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                  </svg>
                  Agende una Consulta
                </a>
              </div>
              <div className="md:w-1/2">
                <img src={banner} alt="Lic. Mariana Chacón Ramírez" className="rounded-lg shadow-lg" />
              </div>
            </div>
          </section>

          {/* Services Section */}
          <section className="py-16 bg-white">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-semibold text-center mb-12 text-gray-800">Áreas de Práctica</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                  <Briefcase className="w-12 h-12 text-gold mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">Derecho de Familia</h3>
                  <p className="text-gray-600">Especializada en divorcios, custodia, pensiones alimenticias y más.</p>
                </div>
                <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                  <FileText className="w-12 h-12 text-gold mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">Derecho Civil</h3>
                  <p className="text-gray-600">Asesoría en contratos, propiedades, sucesiones y litigios civiles.</p>
                </div>
                <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                  <User className="w-12 h-12 text-gold mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">Servicios Notariales</h3>
                  <p className="text-gray-600">Escrituras, autenticaciones, certificaciones y trámites legales.</p>
                </div>
              </div>
            </div>
          </section>

          {/* About Section */}
          <section className="bg-gray-100 py-16">
            <div className="container mx-auto px-4">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 mb-10 md:mb-0">
                  <User className="w-24 h-24 text-gold mb-4" />
                </div>
                <div className="md:w-1/2">
                  <h2 className="text-3xl font-semibold mb-4 text-gray-800">Sobre Mí</h2>
                  <p className="text-gray-600 mb-6">
                    Con más de 15 años de experiencia, me dedico a brindar asesoría legal personalizada
                    y eficaz. Mi compromiso es proteger sus derechos y alcanzar los mejores resultados
                    para usted y su familia.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Testimonials Section */}
          <section className="py-16 bg-white">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-semibold text-center mb-12 text-gray-800">Lo Que Dicen Mis Clientes</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                  <MessageCircle className="w-12 h-12 text-gold mb-4" />
                  <p className="text-gray-600 mb-4">"La Lic. Chacón manejó mi divorcio con profesionalismo y empatía. Estoy muy agradecida por su apoyo durante todo el proceso."</p>
                  <p className="font-semibold text-gray-800">Ana Rodríguez</p>
                </div>
                <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                  <MessageCircle className="w-12 h-12 text-gold mb-4" />
                  <p className="text-gray-600 mb-4">"Excelente asesoría en temas de derecho civil. La Lic. Mariana siempre está disponible y explica todo de manera clara."</p>
                  <p className="font-semibold text-gray-800">Carlos Mendoza</p>
                </div>
              </div>
            </div>
          </section>

          {/* Contact Section */}
          <section className="bg-gray-800 text-white py-16">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-3xl font-semibold mb-8">Contácteme Hoy</h2>
              <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
                <div className="flex items-center">
                  <Phone className="w-6 h-6 mr-2 text-gold" />
                  <span>+506 8888-8888</span>
                </div>
                <div className="flex items-center">
                  <Mail className="w-6 h-6 mr-2 text-gold" />
                  <span>mariana.chacon@bufete.com</span>
                </div>
                <a
                  href={createWhatsAppLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                  </svg>
                  WhatsApp
                </a>
              </div>
            </div>
          </section>
        </div>
    </>
  );
};

export default Home;