import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrash, FaEdit } from 'react-icons/fa';
import Swal from 'sweetalert2'; 
import 'tailwindcss/tailwind.css';
import EditServiceModal from '../modals/EditServiceModal'; 

// Función para formatear los precios en colones costarricenses
const formatPrice = (price) => {
  return `₡${price.toLocaleString('es-CR')}`;
};

const API_URL = 'https://marianachaconramirez.com';

const DataTable = () => {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const servicesPerPage = 5;
  const [token, setToken] = useState(localStorage.getItem('token')); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [currentService, setCurrentService] = useState(null); 

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.post(`${API_URL}/obtenerServicios`, {
          token: token
        });
        
        if (response.data.IsSuccessful) {
          const servicesData = response.data.Details.map(service => ({
            id: service.idServicio,
            name: service.nombreServicio,
            description: service.descripcion,
            basePrice: service.precioBase
          }));
          setServices(servicesData);
        } else {
          console.error('Error fetching services:', response.data.Description);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [token]);

  // Filtrar servicios por nombre
  const filteredServices = services.filter(service =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredServices.length / servicesPerPage);
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);

  const handleEdit = (service) => {
    setCurrentService(service);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: '¿Está seguro que desea eliminar el servicio?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminarlo!',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        const response = await axios.post(`${API_URL}/eliminarServicio`, {
          token: token,
          idServicio: id
        });

        if (response.data.IsSuccessful) {
          // Actualizar la lista de servicios
          setServices(prevServices => prevServices.filter(service => service.id !== id));
          Swal.fire(
            'Eliminado!',
            'El servicio ha sido eliminado.',
            'success'
          );
        } else {
          Swal.fire(
            'Error!',
            `No se pudo eliminar el servicio: ${response.data.Description}`,
            'error'
          );
        }
      }
    } catch (error) {
      Swal.fire(
        'Error!',
        'Hubo un problema al eliminar el servicio.',
        'error'
      );
      console.error('Error deleting service:', error);
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="p-4 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-semibold mb-6 text-gray-900">Servicios</h1>
      
      <input
        type="text"
        placeholder="Buscar por nombre"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-6 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-900"
      />

      {filteredServices.length === 0 ? (
        <p className="text-center text-gray-500">No se encontraron resultados</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead className="bg-gray-50">
                <tr>
                  <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre de Servicio</th>
                  <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descripción</th>
                  <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Precio Base</th>
                  <th className="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentServices.map(service => (
                  <tr key={service.id} className="hover:bg-gray-50">
                    <td className="p-3 text-sm text-gray-900">{service.name}</td>
                    <td className="p-3 text-sm text-gray-500">{service.description}</td>
                    <td className="p-3 text-sm text-gray-900">{formatPrice(service.basePrice)}</td>
                    <td className="p-3 text-sm">
                      <button
                        className="text-gray-900 hover:text-gray-700 mr-3"
                        onClick={() => handleEdit(service)}
                      >
                        <FaEdit size={18} />
                      </button>
                      <button
                        className="text-gray-900 hover:text-gray-700"
                        onClick={() => handleDelete(service.id)}
                      >
                        <FaTrash size={18} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center mt-6">
            <nav>
              <ul className="flex space-x-1">
                {[...Array(totalPages).keys()].map(number => (
                  <li key={number + 1}>
                    <button
                      className={`px-4 py-2 border rounded-md ${number + 1 === currentPage ? 'bg-gray-900 text-white' : 'bg-white text-gray-900 hover:bg-gray-100'}`}
                      onClick={() => handlePageChange(number + 1)}
                    >
                      {number + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </>
      )}

      {isModalOpen && (
        <EditServiceModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            useEffect.fetchServices();
          }}
          service={currentService}
          onUpdate={(updatedService) => {
            setServices(prevServices => 
              prevServices.map(service => 
                service.id === updatedService.id ? updatedService : service
              )
            );
          }}
        />
      )}
    </div>
  );
};

export default DataTable;
