import React, { useState, useEffect } from 'react';
import { useClients } from '../hooks/useClients';
import { useCases } from '../hooks/useCases';
import { useDocuments } from '../hooks/useDocuments';
import EditClientModal from '../components/modals/EditClientModal';
import AddClientModal from '../components/modals/AddClientModal';
import AddCaseModal from '../components/modals/AddCaseModal';
import Casos from '../components/specific/Casos';
import Caso from '../components/specific/Caso';
import Documentos from '../components/specific/Documentos';
import DocumentosCaso from '../components/specific/DocumentosCaso';
import  { decodeToken } from  '../utils/utils';
import { useNavigate } from 'react-router-dom';


const Clientes = () => {
  const { clients, selectedClient, setSelectedClient, handleAddClient, handleUpdateClient, handleDeleteClient, getClients } = useClients();
  const [showDocuments, setShowDocuments] = useState(false);
  const [showCaseDocuments, setShowCaseDocuments] = useState(false);
  
  const { casos, selectedCase, setSelectedCase, showCases, setShowCases, handleShowCases, handleViewCaseClick, handleDeleteCase, handleAddCase, handleAddCaseClick, isAddCaseModalOpen, setIsAddCaseModalOpen, editableCase, setEditableCase, filter, setFilter, handleEstadoChange } = useCases(selectedClient, setShowDocuments, setShowCaseDocuments);
  
  const { documentos, handleShowDocuments, handleViewDocuments, handleDeleteDocument, handleDownloadDocument, handleUploadDocument } = useDocuments(selectedClient, selectedCase, setShowDocuments, setShowCaseDocuments);

  const [searchTerm, setSearchTerm] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editableClient, setEditableClient] = useState({});
  const [isNewClient, setIsNewClient] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  
  useEffect(() => {
    const decodedToken = decodeToken();
    if (decodedToken) {
      setRole(decodedToken.idRol);
    } else { 
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    getClients();
  }, []);


  if (role !== 1) {
    return <div>No tiene permiso para ver esta página.</div>;
  }

  const filteredClients = clients.filter(client =>
    client.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (client) => {
    setEditableClient(client);
    setIsEditModalOpen(true);
    setIsNewClient(false);
  };

  const handleAddClick = () => {
    setEditableClient({ idUsuario: clients.length + 1, idRol: 2, correo: '', cedula: '', nombre: '', apellidos: '', telefono: '' });
    setIsAddModalOpen(true);
    setIsNewClient(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableClient({ ...editableClient, [name]: value });
  };

  const handleCaseInputChange = (e) => {
    const { name, value } = e.target;
    setEditableCase({ ...editableCase, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      if (isNewClient) {
        await handleAddClient(editableClient);
        setIsAddModalOpen(false);
      } else {
        await handleUpdateClient(editableClient);
        setSelectedClient(editableClient);
        setIsEditModalOpen(false);
      }
      getClients();
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  const handleSaveCase = async () => {
    await handleAddCase(editableCase);
    setIsAddCaseModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
  return (
    
    <div className="min-h-screen flex flex-col font-inter bg-gray-100">
      <section className="bg-white shadow-md">
        <nav className="max-w-7xl">
          <ul className="flex flex-col sm:flex-row">
            <div className='w-full sm:w-[225px] border-b-2 sm:border-r-2 sm:border-b-0 p-4 border-gray-200 h-full flex justify-center items-center'>
              <li className="font-semibold text-gray-900">Clientes</li>
            </div>
            <div className='w-full sm:w-[210px] border-b-2 sm:border-r-2 sm:border-b-0 p-4 border-gray-200 h-full flex justify-center items-center hover:bg-gray-700 hover:text-white cursor-pointer transition duration-150' onClick={handleAddClick}>
              <li>Añadir nuevos clientes</li>
            </div>
          </ul>
        </nav>
      </section>
      <div className="flex flex-col sm:flex-row flex-1">
        <button 
          onClick={toggleSidebar} 
          className="sm:hidden bg-white p-2 m-2 rounded text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500"
          aria-label="Toggle sidebar"
        >
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        <aside className={`${isSidebarOpen ? 'block' : 'hidden'} sm:block flex flex-col px-4 pt-4 mt-2 w-full sm:w-64 bg-white shadow-md`}>
          <div className="mb-6">
            <div className="flex items-center border border-gray-300 rounded-lg bg-white shadow-sm">
              <input
                type="text"
                className="w-full p-3 rounded-l-lg outline-none"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="p-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </span>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-sm">
            {filteredClients.map((client, index) => (
              <div
                key={client.idUsuario}
                className="flex items-center justify-between p-4 hover:bg-gray-50 cursor-pointer border-b last:border-b-0 transition duration-150"
                onClick={() => {
                  setSelectedClient(client);
                  setShowCases(false);
                  setSelectedCase(null); 
                  setShowDocuments(false);
                  setShowCaseDocuments(false);
                  setIsSidebarOpen(false);
                }}
              >
                <span className="text-gray-800">{index + 1}. {client.nombre} {client.apellidos}</span>
                <svg className="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
            ))}
          </div>
        </aside>
        <div className="flex-1 p-4 sm:p-8">
          {selectedClient ? (
            <>
              <div className="bg-gray-800 mb-4 text-white p-4 flex flex-col sm:flex-row justify-between rounded-md items-center shadow-md">
                <div className="text-xl font-semibold mb-2 sm:mb-0">Información Cliente</div>
              </div>
              <div className="bg-white rounded-md border-b border-gray-300 mb-4 p-6 shadow-md">
                <div className="flex flex-col sm:flex-row items-center mb-6">
                  <div className="bg-gray-200 w-24 h-24 rounded-full flex justify-center items-center mb-4 sm:mb-0 sm:mr-6">
                    <span role="img" aria-label="user" className="text-4xl">👤</span>
                  </div>
                  <div className="text-center sm:text-left">
                    <div className="text-2xl font-semibold text-gray-800 mb-2">{selectedClient.nombre} {selectedClient.apellidos}</div>
                    <div className="flex flex-wrap justify-center sm:justify-start gap-2">
                      <button className="flex items-center space-x-1 text-black" onClick={() => handleEditClick(selectedClient)}>
                        <span role="img" aria-label="edit">✏️</span>
                        <span>Editar</span>
                      </button>
                      <button className="flex items-center space-x-1 text-red-600 hover:text-red-800 transition duration-150" onClick={() => handleDeleteClient(selectedClient.idUsuario)}>
                        <span role="img" aria-label="delete">🗑️</span>
                        <span>Eliminar</span>
                      </button>
                      <button className="flex items-center space-x-1 text-black transition duration-150" onClick={handleShowCases}>
                        <span role="img" aria-label="cases">📂</span>
                        <span>Casos</span>
                      </button>
                      <button className="flex items-center space-x-1 text-black  transition duration-150" onClick={handleShowDocuments}>
                        <span role="img" aria-label="documents">📄</span>
                        <span>Documentos</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
                  <h2 className="text-xl font-semibold mb-4 text-gray-800 border-b border-gray-200 pb-2">Información General</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <InfoItem label="ID" value={selectedClient.idUsuario} />
                    <InfoItem label="Nombre" value={selectedClient.nombre} />
                    <InfoItem label="Apellidos" value={selectedClient.apellidos} />
                    <InfoItem label="Cédula" value={selectedClient.cedula} />
                    <InfoItem label="Correo Electrónico" value={selectedClient.correo} />
                    <InfoItem label="# Teléfono" value={selectedClient.telefono} />
                  </div>
                </div>
              </div>
              {showCases && !selectedCase && (
                <div className="bg-white rounded-md shadow-md p-6">
                  <Casos casos={casos} handleAddCaseClick={handleAddCaseClick} handleViewCaseClick={handleViewCaseClick} filter={filter} setFilter={setFilter} />
                </div>
              )}
              {selectedCase && !showCaseDocuments && (
                <div className="bg-white rounded-md shadow-md p-6">
                  <Caso caso={selectedCase} handleDeleteCase={handleDeleteCase} handleViewDocuments={handleViewDocuments} handleEstadoChange={handleEstadoChange} />
                </div>
              )}
              {showDocuments && (
                <div className="bg-white rounded-md shadow-md p-6">
                  <Documentos documentos={documentos} handleDownload={handleDownloadDocument} handleDelete={handleDeleteDocument} />
                </div>
              )}
              {showCaseDocuments && (
                <div className="bg-white rounded-md shadow-md p-6">
                  <DocumentosCaso documentos={documentos} handleDownload={handleDownloadDocument} handleDelete={handleDeleteDocument} handleUpload={handleUploadDocument} />
                </div>
              )}
            </>
          ) : (
            <div className="flex-1 p-4 text-center text-gray-500">Seleccione un cliente para ver su información.</div>
          )}
        </div>
      </div>
  
      <EditClientModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        client={editableClient}
        handleInputChange={handleInputChange}
        handleSaveChanges={handleSaveChanges}
      />
  
      <AddClientModal
        isOpen={isAddModalOpen}
        onRequestClose={() => setIsAddModalOpen(false)}
        client={editableClient}
        handleInputChange={handleInputChange}
        handleSaveChanges={handleSaveChanges}
      />
  
      <AddCaseModal
        isOpen={isAddCaseModalOpen}
        onRequestClose={() => setIsAddCaseModalOpen(false)}
        caseData={editableCase}
        handleInputChange={handleCaseInputChange}
        handleSaveCase={handleSaveCase}
      />
    </div>
  );
};

  const InfoItem = ({ label, value }) => (
    <div>
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 font-semibold">{value}</dd>
    </div>
  );

export default Clientes;
