import React, { useState, useEffect } from 'react';
import NavbarInformes from '../components/layout/NavbarInformes';
import CasosYNumerosDeSeguridad from '../components/specific/CasosYNumerosDeSeguridad';
import Citas from '../components/specific/Citas';
import Modificaciones from '../components/specific/Modificaciones';
import ActividadUsuarios from '../components/specific/ActividadUsuarios';
import  { decodeToken } from  '../utils/utils';
import { useNavigate } from 'react-router-dom';

const Informes = () => {
  const [activeLink, setActiveLink] = useState('Casos y numeros de seguridad');
  const navigate = useNavigate();
  const [role, setRole] = useState(null);

  useEffect(() => {
    const decodedToken = decodeToken();
    if (decodedToken) {
      setRole(decodedToken.idRol);
    } else {
      navigate('/login');
    }
  }, []);

  if (role !== 1) {
    return <div>No tiene permiso para ver esta página.</div>;
  }

  return (
    <>
      <NavbarInformes activeLink={activeLink} setActiveLink={setActiveLink} />

      <section className='content w-full h-full font-inter'>
        {activeLink === 'Casos y numeros de seguridad' && (
          <section id="casos-y-numeros-de-seguridad" className='h-svh' >
            <CasosYNumerosDeSeguridad/>
          </section>
        )}
        {activeLink === 'Citas' && (
          <section id="citas" className='h-svh'>
            <Citas/>
          </section>
        )}
        {activeLink === 'Modificaciones' && (
          <section id="modificaciones" className='h-svh'>
             <Modificaciones/>
          </section>
        )}
        {activeLink === 'Actividad de Usuarios' && (
          <section id="actividad-usuarios" className='h-svh'>
            <ActividadUsuarios/>
          </section>
        )}
      </section>
    </>
  );
};

export default Informes;
